export const environment = {
  production: true,
  currentEnv: 'production',
  api: 'https://api.wellabe.fit',
  rollbar: {
    environment: 'production',
    enabled: true,
  },
  cookie: {
    privacy_link: 'https://web.wellabe.fit/terms/privacy-statement',
  },
  console: {
    enabled: false,
  },
  analytics: {
    trackerUrl: 'https://track.wellabe.fit/',
    mobileSiteId: '2',
    webSiteId: '11',
    subdomain: '*.wellabe.fit',
  },
  userConstraints: {
    maxAge: 120,
    minAge: 16,
    minHeight: 100,
    maxHeight: 250,
  }, // user register constraints based on API documentation
};
