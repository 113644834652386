import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api';
import { AccountStep } from '../../shared/interfaces/account.interface';
import { PostChangePasswordBody } from '../../shared/interfaces/api/auth-api.interface';
import { UserApi } from '../../shared/interfaces/api/checkup-api.interface';
import {
  PutUserBody,
  UserData,
  UserProvidersApiResponse,
} from '../../shared/interfaces/api/user-api.interface';

/*
  Generated class for the AccountApiServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  constructor(private apiService: ApiService) {}

  getUser(): Promise<UserData> {
    return this.apiService.get(`/app/account`).toPromise();
  }

  putUser(body: PutUserBody): Promise<UserData> {
    return this.apiService.put(`/app/account`, body).toPromise();
  }

  getProviders(): Promise<UserProvidersApiResponse[]> {
    return this.apiService.get(`/app/account/providers`).toPromise();
  }

  getAccountStep(): Observable<AccountStep> {
    return this.apiService.get(`/app/account/step`);
  }

  postChangePassword(bodyParam: PostChangePasswordBody): Promise<UserApi> {
    return this.apiService.put(`/app/account/password`, bodyParam).toPromise();
  }

  // This route returns []
  postLogoutCurrentSession(): Promise<any> {
    return this.apiService.post(`/app/account/logout`, {}).toPromise(); // This route should be "auth/logout"
  }

  // this route returns []
  postLogoutAllSessions(): Promise<any> {
    return this.apiService.post(`/app/account/logout/all`, {}).toPromise(); // This route should be "auth/logout/all"
  }

  postAccountReset(password: string): Observable<any> {
    return this.apiService.post(`/app/account/reset`, { password });
  }

  getShouldPromptEmail(): Observable<{ prompt_email: boolean }> {
    return this.apiService.get(`/app/account/prompt-email`);
  }

  postUserEmail(email: string, password: string) {
    return this.apiService.post(`/app/account/email`, { email, password });
  }
}
