import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';
import { ComponentsModule } from '../../components/components.module';
import { EmailRequiredModalPage } from './email-required-modal.page';

@NgModule({
  imports: [CommonModule, ComponentsModule, IonicModule, PipesModule, DirectivesModule],
  declarations: [EmailRequiredModalPage],
})
export class EmailRequiredModalModule {}
