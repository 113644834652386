import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
  Params,
} from '@angular/router';
import { DataProviderService } from '../../services/data-provider/data-handler.service';
import { StepHandlerService } from '../../services/step-handler/step-handler.service';
import { VoucherService } from '../../services/voucher/voucher.service';
import { AppModeService } from '../../services/app-mode/app-mode.service';
import { ProviderQuery } from '../../services/provider/provider.query';
import { AccountApiService } from '../../api/account-api.service';

@Injectable({
  providedIn: 'root',
})
export class StepGuard implements CanActivate {
  constructor(
    private dataProvider: DataProviderService,
    private router: Router,
    private stepHandler: StepHandlerService,
    private voucherService: VoucherService,
    private appModeService: AppModeService,
    private providerQuery: ProviderQuery,
    private accountApiService: AccountApiService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.appModeService.waitForInitialization();
    const { url } = state;
    const { queryParams } = state.root;

    this.saveVoucherParam(queryParams);
    this.saveProviderHandleParam(queryParams);

    const authenticated = await this.checkLogin();
    if (!authenticated) {
      if (this.isAuthenticatedUrl(url)) {
        this.router.navigate(['/home'], { queryParamsHandling: 'merge' });
        return false;
      }

      return true;
    }

    if (url !== '/email-required') {
      const shouldPromptEmail = await this.checkPromptEmail();
      if (shouldPromptEmail) {
        this.router.navigate(['/email-required']);
        return false;
      }
    }

    const { step } = this.dataProvider.data.user.info;
    return this.checkStep(url, step);
  }

  handleWrongStep() {
    this.stepHandler.goToInitialPage();
    return false;
  }

  private checkLogin(): Promise<boolean> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      // Wait for provider to load all data
      // eslint-disable-next-line consistent-return
      this.dataProvider.isProviderReady().subscribe((isReady) => {
        if (isReady) {
          const authenticated = !!(
            this.dataProvider.data.authentication != null &&
            this.dataProvider.data.user &&
            this.dataProvider.userStep
          );

          if (authenticated) {
            resolve(true);
          } else {
            resolve(false);
          }

          return authenticated;
        }
      });
    });
  }

  private checkStep(url: string, step: string) {
    // Strips params in the format ?parms
    const strippedUrl = url.split('?')[0].split(';')[0];

    // Strips the params in the format route/{param}/route/{param}
    // eg.: biomarker-group/4/biomarker/1
    // result: biomarker-group/biomarker/
    let stripParams = strippedUrl.replace(/(\d+\/*)/g, '');

    // Remove last `/`
    if (stripParams.length > 0) {
      stripParams =
        stripParams[stripParams.length - 1] === '/'
          ? stripParams.substring(0, stripParams.length - 1)
          : stripParams;
    }

    switch (stripParams) {
      case '/email-verification':
        return step === 'email_verification' ? true : this.handleWrongStep();
      case '/appointment':
        // TODO: Check if there are any parameters when user is on app step
        return step === 'appointment' || step === 'app' || step === 'waiting-checkup'
          ? true
          : this.handleWrongStep();
      case '/onboarding-questions':
      case '/onboarding/waiting-checkup':
        return step === 'waiting-checkup' || step === 'app' ? true : this.handleWrongStep();
      case '/onboarding/results':
      case '/results':
      case '/activities':
      case '/todays-goals':
      case '/appointments':
      case '/tabs/results':
      case '/tabs/results/health-summary':
      case '/tabs/results/biomarker-group':
      case '/tabs/results/biomarker-group/information':
      case '/tabs/results/biomarker-group/biomarker':
      case '/tabs/results/add-biomarker':
      case '/tabs/results/biomarker-group/biomarker/measurements':
      case '/tabs/activities':
      case '/tabs/activities/articles':
      case '/tabs/activities/journey':
      case '/tabs/activities/journey/challenges':
      case '/tabs/activities/questionnaire':
      case '/tabs/activities/completed':
      case '/tabs/activities/offers':
      case '/tabs/todays-goals':
      case '/tabs/appointments':
      case '/tabs/settings':
      case '/tabs/settings/request-data':
      case '/tabs/settings/review-analytics':
      case '/tabs/settings/delete-account':
      case '/tabs/settings/email-settings':
      case '/tabs/appointments/past':
      case '/change-password':
      case '/feedback':
      case '/activities/article':
      case '/activities/journey/challenge':
      case '/questionnaire':
        return step === 'app' ? true : this.handleWrongStep();
      default:
        this.handleWrongStep();
    }

    return false;
  }

  private isAuthenticatedUrl(url) {
    const unauthorizedRoutes = ['/home', '/login', '/register', '/forgot-password', '/terms'];
    const isUnauthorizedRoute = !unauthorizedRoutes.some((route) => url.includes(route));

    return isUnauthorizedRoute;
  }

  private saveVoucherParam(params: Params) {
    if (params?.voucher) {
      this.voucherService.addVoucherToStorage(params.voucher);
    }
  }

  private saveProviderHandleParam(params: Params) {
    if (params?.provider) {
      this.providerQuery.setHandle(params.provider);
    }
  }

  private checkPromptEmail(): Promise<boolean> {
    return new Promise((resolve) => {
      this.accountApiService.getShouldPromptEmail().subscribe((result) => {
        resolve(result.prompt_email);
      });
    });
  }
}
